import {
    faAlignLeft,
    faBoxesStacked,
    faLocationDot,
    faTriangleExclamation,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';
import { t } from 'i18next';
import { useState } from 'react';
import {
    AutocompleteInput,
    Create,
    Edit,
    FileField,
    FileInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    useGetList,
    useNotify,
    useRecordContext,
    useRefresh
} from 'react-admin';
import { useForm } from 'react-final-form';
import { DEFECTIVE_MATERIAL_ANOMALY_TYPES, DEFECTIVE_MATERIAL_STATES } from '../constants';
import { arrayToChoices } from '../utils';
import { MySaveButton } from './react-admin-components/Buttons';
import { MyTextInput } from './react-admin-components/Inputs';

const MaterialInput = props => {
    const record = useRecordContext(props);
    return <MyTextInput {...props} source={record.rawMaterial ? 'rawMaterial' : 'material.name'} />;
};

const OriginInput = props => {
    const record = useRecordContext(props);
    return <MyTextInput {...props} source={record.rawOrigin ? 'rawOrigin' : 'origin.name'} />;
};

export const MaintenanceMaterialEdit = props => {
    const notify = useNotify();
    const refresh = useRefresh();

    return (
        <Edit
            {...props}
            className='create-form'
            mutationMode='optimistic'
            transform={data => ({ id: data.id, state: data.state })}
            onSuccess={() => {
                props.closeDialog();
                refresh();
                notify(t('maintenance.updated'), { undoable: false });
            }}
            onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
        >
            <SimpleForm toolbar={<MySaveButton label={t('layout.validate-form')} />}>
                <MaterialInput label={t('defective-materials.material-reference')} labelUnderInput disabled />
                <OriginInput label={t('defective-materials.material-location')} labelUnderInput disabled />
                <SelectInput
                    source='state'
                    choices={arrayToChoices(DEFECTIVE_MATERIAL_STATES, 'defective-materials')}
                    validate={required()}
                    label={t('defective-materials.state')}
                    className='input select label-under-input'
                />
            </SimpleForm>
        </Edit>
    );
};

export const MaintenanceMaterialCreateDialog = props => {
    const { open, setOpen, closeIcon } = props;
    return (
        <StyledDialog open={open} setOpen={() => setOpen()} onClose={() => setOpen(false)} scroll='paper'>
            <div>
                {closeIcon && (
                    <img
                        src='assets/close-icon.svg'
                        alt='Close icon'
                        onClick={() => setOpen(false)}
                        className='close-icon'
                    />
                )}
                <div className='dialog-header'>
                    <img src='assets/wrench.png' alt='Maintenance' />
                    <div className='dialog-header-text'>
                        <h2 className='dialog-title'>{t('defective-materials.declare-specific-anomaly')}</h2>
                        <p className='dialog-subtitle'>{t('defective-materials.specific-anomaly-alert')}</p>
                    </div>
                </div>
                <MaintenanceMaterialCreate
                    basePath='/maintenanceMaterials'
                    resource='maintenanceMaterials'
                    closeDialog={() => setOpen(false)}
                />
            </div>
        </StyledDialog>
    );
};

export const MaintenanceMaterialCreate = props => {
    const refresh = useRefresh();
    const notify = useNotify();

    return (
        <StyledCreate
            {...props}
            className='create-form'
            transform={data => {
                const body = {
                    anomalyType: data.anomalyType,
                    comment: data.comment,
                    [data.isCustomMaterial ? 'rawMaterial' : 'materialId']: data.material,
                    [data.isCustomLocation ? 'rawOrigin' : 'originId']: data.origin,
                    image: data.image
                };

                let formData = new FormData();
                Object.keys(body).forEach(key => {
                    formData.append(key, key === 'image' ? body[key]?.rawFile : body[key]);
                });

                return { formData };
            }}
            onSuccess={() => {
                props.closeDialog();
                refresh();
                notify(t('defective-materials.created'), { undoable: false });
            }}
            onFailure={error => notify(`Error: ${error.message}`, { type: 'warning' })}
        >
            <SimpleForm toolbar={<StyledSaveButton label={t('defective-materials.validate-form')} />}>
                <MaterialAutocompleteInput />
                <LocationAutocompleteInput />
                <StyledSelectInput
                    source='anomalyType'
                    choices={arrayToChoices(DEFECTIVE_MATERIAL_ANOMALY_TYPES, 'defective-materials')}
                    validate={required()}
                    label={
                        <>
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <span>{t('defective-materials.anomaly-type')} *</span>
                        </>
                    }
                />
                <StyledFileInput
                    source='image'
                    accept='image/*,.pdf'
                    label={
                        <>
                            <FontAwesomeIcon icon={faUpload} />
                            <span>{t('defective-materials.upload-file')}</span>
                        </>
                    }
                >
                    <FileField source='src' title='rawFile.path' target='_blank' />
                </StyledFileInput>
                <StyledTextInput
                    source='comment'
                    multiline
                    margin='normal'
                    minRows={4}
                    maxRows={10}
                    label={
                        <>
                            <FontAwesomeIcon icon={faAlignLeft} />
                            <span>{t('defective-materials.comment')} *</span>
                        </>
                    }
                    helperText={t('defective-materials.comment-title')}
                    validate={required()}
                />
            </SimpleForm>
        </StyledCreate>
    );
};

const MaterialAutocompleteInput = () => {
    const [materialSearch, setMaterialSearch] = useState();
    const [customMaterial, setCustomMaterial] = useState();
    const materials = useGetList(
        'materials',
        { page: 1, perPage: 50 },
        { field: 'name', order: 'ASC' },
        materialSearch ? { searchFields: { name: `%${materialSearch}%` } } : {}
    );

    const form = useForm();
    form.change('isCustomMaterial', !!customMaterial);

    const materialChoices = [
        ...(customMaterial ? [{ id: customMaterial, name: customMaterial, custom: true }] : []),
        ...materials.ids.map(id => {
            return { id: id, name: materials.data[id].name };
        })
    ];

    return (
        <>
            <StyledAutocompleteInput
                source='material'
                choices={materialChoices}
                validate={required()}
                resettable
                clearAlwaysVisible
                label={
                    <>
                        <FontAwesomeIcon icon={faBoxesStacked} />
                        <span>{t('defective-materials.material-reference')} *</span>
                    </>
                }
                helperText={t('defective-materials.select-material-custom')}
                createLabel=''
                createItemLabel={t('defective-materials.select-custom-item')}
                onUserAction={action => {
                    if (action.type === Downshift.stateChangeTypes.changeInput && action.inputValue) {
                        setMaterialSearch(action.inputValue);
                    } else if (
                        action.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem &&
                        action.inputValue === undefined
                    ) {
                        setMaterialSearch();
                    }
                }}
                onSelect={record => !record.custom && setCustomMaterial()}
                onCreate={name => {
                    setCustomMaterial(name);
                    return { id: name, name };
                }}
                matchSuggestion={() => true}
            />
            <TextInput source='isCustomMaterial' style={{ display: 'none' }} />
        </>
    );
};

const LocationAutocompleteInput = () => {
    const [locationSearch, setLocationSearch] = useState();
    const [customLocation, setCustomLocation] = useState();
    const locations = useGetList(
        'locations',
        { page: 1, perPage: 50 },
        { field: 'type,name', order: 'ASC' },
        {
            active: true,
            ...(locationSearch ? { searchFields: { name: `%${locationSearch}%` } } : {})
        }
    );

    const form = useForm();
    form.change('isCustomLocation', !!customLocation);

    const locationChoices = [
        ...(customLocation ? [{ id: customLocation, name: customLocation }] : []),
        ...locations.ids.map(id => {
            return { id: id, name: locations.data[id].name };
        })
    ];

    return (
        <>
            <StyledAutocompleteInput
                source='origin'
                choices={locationChoices}
                validate={required()}
                resettable
                clearAlwaysVisible
                label={
                    <>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span>{t('defective-materials.material-location')} *</span>
                    </>
                }
                helperText={t('defective-materials.select-location-custom')}
                createLabel=''
                createItemLabel={t('defective-materials.select-custom-item')}
                onUserAction={action => {
                    if (action.type === Downshift.stateChangeTypes.changeInput && action.inputValue) {
                        setLocationSearch(action.inputValue);
                    } else if (
                        action.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem &&
                        action.inputValue === undefined
                    ) {
                        setLocationSearch();
                    }
                }}
                onSelect={record => !record.custom && setCustomLocation()}
                onCreate={name => {
                    setCustomLocation(name);
                    return { id: name, name };
                }}
                matchSuggestion={() => true}
            />
            <TextInput source='isCustomLocation' style={{ display: 'none' }} />
        </>
    );
};

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        width: '100%',
        maxWidth: '700px',
        position: 'relative',
        '& > div': {
            padding: '40px 40px 30px',
            '& .dialog-header': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '30px',
                '& img': {
                    width: '80px',
                    marginRight: '10px'
                },
                '& .dialog-header-text': {
                    marginLeft: '10px',
                    '& .dialog-title': {
                        color: '#2c3e50',
                        marginBottom: '20px',
                        fontSize: '1.5em'
                    },
                    '& .dialog-subtitle': {
                        color: '#7f8c8d',
                        marginBottom: '0',
                        marginTop: '0',
                        fontSize: '14px'
                    }
                }
            }
        }
    },
    '& .close-icon': {
        position: 'absolute',
        top: '20px',
        right: '20px',
        cursor: 'pointer'
    }
});

const StyledCreate = styled(Create)({
    '& .MuiPaper-root': {
        boxShadow: 'none',
        padding: '10px'
    },
    '& .MuiCardContent-root': {
        padding: '0'
    },
    '$ .MuiInputLabel-formControl': {
        position: 'relative'
    }
});

const inputStyle = {
    '&': {
        width: '100%'
    },
    '& .MuiFormLabel-root': {
        position: 'relative',
        transform: 'none',
        fontSize: '16px',
        color: '#34495e',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: '25px'
        },
        '&.Mui-error': {
            color: '#e74c3c'
        }
    },
    '& .MuiInputBase-root': {
        width: '100%',
        fontSize: '17px',
        color: '#2c3e50',
        borderRadius: '6px',
        '&.MuiInputBase-multiline': {
            padding: '0'
        },
        '&:after, &:before': {
            borderBottom: 0
        },
        '&.Mui-error .MuiInputBase-input': {
            borderColor: '#e74c3c'
        }
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
        transform: 'none'
    },
    '& .MuiInputBase-input': {
        width: '100%',
        padding: '12px',
        borderRadius: '6px',
        border: '1px solid #a1b0cc',
        backgroundColor: '#ffffff',
        transition: 'border-color 0.3s',
        '&:focus': {
            outline: 'none',
            borderColor: '#3498db'
        }
    }
};

const StyledSelectInput = styled(SelectInput)(inputStyle);
const StyledTextInput = styled(TextInput)(inputStyle);
const StyledAutocompleteInput = styled(AutocompleteInput)(inputStyle);

const CustomFileInput = withStyles({
    dropZone: {
        backgroundColor: '#ffffff',
        border: '1px dashed #a1b0cc',
        borderRadius: '6px',
        padding: '20px',
        '& p': {
            fontSize: '17px',
            color: '#7f8c8d',
            fontWeight: 'normal'
        }
    }
})(FileInput);
const StyledFileInput = styled(CustomFileInput)(inputStyle);

const StyledSaveButton = styled(SaveButton)({
    '&': {
        float: 'right',
        backgroundColor: '#1a2339',
        padding: '10px 20px',
        marginTop: '20px',
        borderRadius: '6px',
        border: 'none',
        color: '#ffffff',
        fontWeight: '600',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#6ae7d0'
        }
    }
});
